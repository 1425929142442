<template>
	<div class="page-buttons">
		<a-button v-for="(button, buttonI) in pageButtons" :key="`pageButtons${buttonI}`"
			:type="button.type || 'primary'" :ghost="Boolean(button.ghost)" :icon="button.ant || ''"
			@click="button.callback()">
			<i :class="`${button.fe ? 'fe' : 'fa'} ${button.fe || ''} ${button.fa || ''}`" />
			{{ button.label }}
		</a-button>
	</div>
</template>

<script>
export default {
	computed: {
		pageButtons() { return this.$store.state.pageButtons }
	}
}
</script>
