<template>
	<SubBarGlobal :currentCrumb="$store.state.appData.filter.label">
		<div class="dF">
			<a-auto-complete style="width: 300px" placeholder="Search for a contract..."
				:dropdown-match-select-width="false" :dropdown-style="{ width: '300px' }" option-label-prop="value"
				@search="handleSearch" class="mr-4">
				<template slot="dataSource">
					<!-- <a-select-opt-group> -->
					<a-select-option @click="$router.push(`/template/${temp.id}/prepare`)"
						v-for="(temp, tempI) in searchResult" :key="tempI" :value="temp.id">
						<div class="dF aC">
							<div class="f1">
								<div>
									<div class="dF">
										<Icons type="contract-file" style="width:10px;color:var(--red);" class="mr-2" />
										<strong class="block">{{ temp.name }}</strong>
									</div>
									<small>{{ lookup[temp.type] }} - {{ temp.pages.length }} pages</small>

								</div>
							</div>
						</div>
					</a-select-option>
				</template>
				<a-input>
					<a-icon slot="suffix" type="search" />
				</a-input>
			</a-auto-complete>
			<PageButtons />
		</div>
	</SubBarGlobal>
</template>

<script>
import Icons from '@/components/common/BHIcons'
import SubBarGlobal from "bh-mod/components/layout/SubBarGlobal";
import PageButtons from "bh-mod/components/layout/SubBarGlobal/PageButtons";

export default {
	components: { SubBarGlobal, PageButtons, Icons },
	data() {
		return {
			loadSearch: false,
			searchResult: [],
			lookup: {
				lease: 'Main Lease Template',
				purchase: "Main Purchase Template",
				amendLease: 'Amendment Lease Template',
				amendPurchase: 'Amendment/Document Template',
				reservation: 'Reservation Agreement Template',
				mutualRelease: 'Mutual Release Agreement Template',
				other: 'Supporting Document Template'
			},
		};
	},

	computed: {
		pageButtons() {
			return this.$store.state.pageButtons;
		},
		crumbItems() {
			if (this.$store.state.crumbs && this.$store.state.crumbs.length)
				return this.$store.state.crumbs;
			else if (this.$route.meta.crumbName)
				return [{ name: this.$route.meta.crumbName }];
			else return [];
		},
		templates() {
			let temp = this.$store.state.appData.templates || {}
			return Object.values(temp)
		},
	},
	methods: {
		handleSearch(input) {
			if (input && input.length > 2) {
				this.searchResult = this.templates.filter(({ name }) => {
					name = name.toLowerCase()
					input = input.toLowerCase()
					return name.includes(input)
				})
			} else {
				return this.searchResult = []
			}
		},
	},
};
</script>

<style lang="scss">
</style>
