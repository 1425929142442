<template>
	<div v-if="instance" class="p-4 dF aC">
		<div style="align-items: center; display: block !important">
			<a-breadcrumb>
				<a-breadcrumb-item>{{ instance.name }}</a-breadcrumb-item>
				<a-breadcrumb-item><router-link to="/">{{
					theApp.initial
				}}</router-link></a-breadcrumb-item>
				<a-breadcrumb-item v-for="(item, itemI) in crumbItems" :key="item.name">
					<span :style="itemI == crumbItems.length - 1
							? 'color:var(--orange)'
							: ''
						" v-if="!item.link || itemI === crumbItems.length">{{ item.name }}</span>
					<router-link v-else :to="item.link">{{
						item.name
					}}</router-link>
				</a-breadcrumb-item>
			</a-breadcrumb>
		</div>

		<div class="ml-auto d-none d-sm-flex">
			<slot />
		</div>
	</div>
</template>
<script>
export default {
	props: ["currentCrumb"],

	computed: {
		crumbItems() {
			if (this.currentCrumb) return [{ name: this.currentCrumb }];
			else if (
				this.$store.state.crumbs &&
				this.$store.state.crumbs.length
			)
				return this.$store.state.crumbs;
			else if (this.$route.meta.crumbName)
				return typeof this.$route.meta.crumbName === "string"
					? [{ name: this.$route.meta.crumbName }]
					: this.$route.meta.crumbName;
			return [{ name: "unknown page" }];
		},
		theApp() {
			let theApp = {};
			let app = this.$store.state.theApp;
			theApp = app;
			if (app.media.initial) {
				theApp.initial = app.media.initial;
			} else if (app && app.name) {
				let fullName = app.name.split(" ");
				let initial = "";
				fullName.forEach((word) => {
					initial = `${initial}${word.charAt(0)}`;
				});
				initial.toUpperCase();

				theApp.initial = initial;
			}
			return theApp;
		},
		instance() {
			return this.$store.state.instance || {};
		},
	},
};
</script>

<style lang="scss"></style>
